import { Link } from "react-router-dom";
import styled from "styled-components";
import { handleSizeType } from "../../Utilities/Sizes";
import { theme } from "styled-tools";
import { handleColor } from "../../Utilities/Icons";

export const LinkStyled = styled(Link)<{ variant: string; size: number; weight:number; }>`
  color: ${(props) => props.variant ? handleColor(props.variant) : theme("Background_01")};
  cursor: pointer;
  font-family: Poppins;
  font-weight: ${(props) => (props.weight ? props.weight : "400")};
  padding: 0 3px;
  font-size: ${(props) => (props.size ? handleSizeType(props.size) : "12px")};
  text-decoration: ${(props) =>
    props.variant === "white" || props.variant === "none" ? "none" : "underline"};

  &:hover {
    text-decoration: ${(props) =>
      props.variant === "white" ? "underline" : "none"};
  }
`;
