import { useEffect, useRef, useState } from "react";

import Icon from "../Icon";

import { PropsLayout } from "../../Models/Layout";

import {
  FloatingButton,
  ContainerModal
} from "./FloatingHelpButton.styled";

const FloatingHelpButton = (props: PropsLayout) => {
  const { children, position } = props;
  const [ openBtn, setOpenBtn ] = useState(false);
  const FloatingBtn = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (FloatingBtn.current && !FloatingBtn.current?.contains(event.target as Node)) {
        setOpenBtn(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [FloatingBtn]);

  return (
    <div ref={FloatingBtn}>
      <ContainerModal $show={openBtn} $position={position}>
        {children}
      </ContainerModal>
      <FloatingButton onClick={() => setOpenBtn(!openBtn)} $position={position} id="floatingButton">
        <Icon
          icon={openBtn ? "cancel" : "headset"}
          color="white"
          fillIcon={false}
          size="medium"
        />
      </FloatingButton>
    </div>
  )
};

export default FloatingHelpButton;