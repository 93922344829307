// Own icons Components
import AlertTriangleIcon from "../Components/Icons/AlertTriangle.tsx";
import AlertRedTriangleIcon from "../Components/Icons/AlertRedTriangle.tsx";
import AlertWarningIcon from "../Components/Icons/AlertWarning.tsx";
import ArrowLeftIcon from "../Components/Icons/ArrowLeft.tsx";
import ArrowRightIcon from "../Components/Icons/ArrowRight.tsx";
import ArrowTopIcon from "../Components/Icons/ArrowTop.tsx";
import ArrowDownIcon from "../Components/Icons/ArrowDown.tsx";
import BankIcon from "../Components/Icons/Bank.tsx";
import BankAccountIcon from "../Components/Icons/BankAccount.tsx";
import BagIcon from "../Components/Icons/Bag.tsx";
import BellIcon from "../Components/Icons/Bell.tsx";
import BuildingStoreIcon from "../Components/Icons/BuildingStore.tsx";
import CancelIcon from "../Components/Icons/Cancel.tsx";
import CashIcon from "../Components/Icons/Cash.tsx";
import ChatLeftIcon from "../Components/Icons/ChatLeft.tsx";
import CheckIcon from "../Components/Icons/Check.tsx";
import CheckCircleGreenIcon from "../Components/Icons/CheckCircleGreen.tsx";
import CheckPurpleIcon from "../Components/Icons/CheckPurple.tsx";
import CheckCirclePurpleIcon from "../Components/Icons/CheckCirclePurple.tsx";
import ChevronDownIcon from "../Components/Icons/ChevronDown.tsx";
import ChevronRightIcon from "../Components/Icons/ChevronRight.tsx";
import ChevronUpIcon from "../Components/Icons/ChevronUp.tsx";
import CircleCheckIcon from "../Components/Icons/CircleCheck.tsx";
import CircleArrowLeftGreyIcon from "../Components/Icons/CircleArrowLeftGrey.tsx";
import CirlePlusIcon from "../Components/Icons/CirlePlus.tsx";
import CircleInfoIcon from "../Components/Icons/CircleInfo.tsx";
import CircleInfoBlackIcon from "../Components/Icons/CircleInfoBlack.tsx";
import CircleSelectedIcon from "../Components/Icons/CircleSelected.tsx";
import CircleDotIcon from "../Components/Icons/CircleDot.tsx";
import CircleXIcon from "../Components/Icons/CircleX.tsx";
import CirleXRedIcon from "../Components/Icons/CircleXRed.tsx";
import CircleXWarningIcon from "../Components/Icons/CircleXWarning.tsx";
import CircleUserIcon from "../Components/Icons/CircleUser.tsx";
import ClockIcon from "../Components/Icons/Clock.tsx";
import CoinGradientIcon from "../Components/Icons/CoinGradient.tsx";
import CoinDocument from "../Components/Icons/CoinDocument.tsx";
import CoinIcon from "../Components/Icons/Coin.tsx";
import CreditCardIcon from "../Components/Icons/CreditCard.tsx";
import DocumentIcon from "../Components/Icons/Document.tsx";
import CopyIcon from "../Components/Icons/Copy.tsx";
import DownloadIcon from "../Components/Icons/Download.tsx";
import EditIcon from "../Components/Icons/Edit.tsx";
import EyeOffIcon from "../Components/Icons/EyeOff.tsx";
import EyeIcon from "../Components/Icons/Eye.tsx";
import ErrorCircleIcon from "../Components/Icons/ErrorCircle.tsx";
import ErrorWarningCircleIcon from "../Components/Icons/ErrorWarningCircle.tsx";
import GroupIcon from "../Components/Icons/Group.tsx";
import FaqsIcon from "../Components/Icons/Faqs.tsx";
import HeadsetIcon from "../Components/Icons/Headset.tsx";
import LinkIcon from "../Components/Icons/Link.tsx";
import LinkedAccountIcon from "../Components/Icons/LinkedAccount.tsx";
import LockIcon from "../Components/Icons/Lock.tsx";
import LogoutIcon from "../Components/Icons/Logout.tsx";
import MailIcon from "../Components/Icons/Mail.tsx";
import MenuIcon from "../Components/Icons/Menu.tsx";
import MessageIcon from "../Components/Icons/Message.tsx";
import PiggyBankIcon from "../Components/Icons/PiggyBank.tsx";
import PhoneIcon from "../Components/Icons/Phone.tsx";
import PriceCoinIcon from "../Components/Icons/PriceCoin.tsx";
import PlusIcon from "../Components/Icons/Plus.tsx";
import RefreshIcon from "../Components/Icons/Refresh.tsx";
import ShareIcon from "../Components/Icons/Share.tsx";
import SettingsIcon from "../Components/Icons/Settings.tsx";
import SendIcon from "../Components/Icons/Send.tsx";
import ShieldCheckIcon from "../Components/Icons/ShieldCheck.tsx";
import SwitchIcon from "../Components/Icons/SwitchHorizontal.tsx";
import TrashIcon from "../Components/Icons/Trash.tsx";
import TrashGrayIcon from "../Components/Icons/TrashGray.tsx";
import UnderReviewIcon from "../Components/Icons/UnderReview.tsx";
import UploadIcon from "../Components/Icons/Upload.tsx";
import UploadLineIcon from "../Components/Icons/UploadLine.tsx";
import USAFlagIcon from "../Components/Icons/USAFlag.tsx";
import UserIcon from "../Components/Icons/User.tsx";
import UsersIcon from "../Components/Icons/Users.tsx";
import WalletIcon from "../Components/Icons/Wallet.tsx";
import WhatsAppIcon from "../Components/Icons/WhatsApp.tsx";
import WarningCircleIcon from "../Components/Icons/WarningCircle.tsx";
import AlertCheckCircleIcon from "../Components/Icons/AlertCheckCircle.tsx";
import AlertInfoErrorCircleIcon from "../Components/Icons/AlertInfoErrorCircle.tsx";
import DoorIcon from "../Components/Icons/Door.tsx";
import MXIcon from "../Components/Icons/MX.tsx";
import USIcon from "../Components/Icons/US.tsx";
import IdCardIcon from "../Components/Icons/IdCard.tsx";
import VisaPassportIcon from "../Components/Icons/VisaPassport.tsx";
import MoreIcon from "../Components/Icons/More.tsx";
import RightLeftIcon from "../Components/Icons/RightLeft.tsx";
import NewArrowRightIcon from "../Components/Icons/NewArrowRight.tsx";
import LocationIcon from "../Components/Icons/Location.tsx";
import SearchIcon from "../Components/Icons/Search.tsx";
import CashCancelIcon from "../Components/Icons/CashCancel.tsx";
import ArrowDownTutorialIcon from "../Components/Icons/ArrowDownTutorialIcon.tsx";
import DeviceMobileIcon from "../Components/Icons/DeviceMobile.tsx";
import SpeakerPhoneIcon from "../Components/Icons/SpeakerPhone.tsx";
import CheckCircleGreen2Icon from "../Components/Icons/CheckCircleGreen2.tsx";
import NewCopyIcon from "../Components/Icons/NewCopy.tsx";
import WorldIcon from "../Components/Icons/World.tsx";
import OptionsIcon from "../Components/Icons/Options.tsx";
import PayrollIcon from "../Components/Icons/Payroll.tsx";
import StarIcon from "../Components/Icons/Star.tsx";
import DocumentsIcon from "../Components/Icons/Documents.tsx";
import HeartIcon from "../Components/Icons/Heart.tsx";
import FlameDiscountIcon from "../Components/Icons/FlameDiscount.tsx";
import FlameDiscountGradientIcon from "../Components/Icons/FlameDiscountGradient.tsx";
import DiscountPercentBadge from "../Components/Icons/DiscountPercentBadge.tsx";
import DiscountPercentFire from "../Components/Icons/DiscountPercentFire.tsx";

// Own images collection
import CardSendola from "@/Assets/Img/SendolaCard.png";
import CardDisableSendola from "@/Assets/Img/SendolaCardDisable.png";
import LoaderImage from "@/Assets/Img/bgLoader.jpg";

import { IconTypes, ImgTypes } from "../Models/IconInterface";
import SquareMailIcon from "../Components/Icons/SquareMail.tsx";
import UnLinkedAccountIcon from "../Components/Icons/UnLinkedAccount.tsx";
import IDCardAmericanIcon from "../Components/Icons/IDCardAmerican.tsx";
import LicenseCardAmericanIcon from "../Components/Icons/LicenseCardAmerican.tsx";
import PassportIcon from "../Components/Icons/Passport.tsx";
import FaceIdIcon from "../Components/Icons/AlertCheckCircle copy.tsx";
import { theme } from "../Contexts/ThemeProvider/Theme.tsx";
import CurrencyDollarExchangeIcon from "../Components/Icons/CurrencyDollar.tsx";
import TimeClockStopIcon from "../Components/Icons/TimeClockStop.tsx";
import TimeClockLoadingIcon from "../Components/Icons/TimeClockLoading.tsx";

export const iconTypes: IconTypes = {
  alertCheckCircle: AlertCheckCircleIcon,
  alertInfoErrorCircle: AlertInfoErrorCircleIcon,
  alertTriangle: AlertTriangleIcon,
  alertRedTriangle: AlertRedTriangleIcon,
  alertWarning: AlertWarningIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowTop: ArrowTopIcon,
  arrowDown: ArrowDownIcon,
  arrowDownTutorial: ArrowDownTutorialIcon,
  bank: BankIcon,
  bankAccount: BankAccountIcon,
  bell: BellIcon,
  bag: BagIcon,
  buildingStore: BuildingStoreIcon,
  cancel: CancelIcon,
  cash: CashIcon,
  cashCancel: CashCancelIcon,
  chatLeft: ChatLeftIcon,
  check: CheckIcon,
  checkCircleGreen: CheckCircleGreenIcon,
  checkCircleGreen2: CheckCircleGreen2Icon,
  checkPurple: CheckPurpleIcon,
  checkCirclePurple: CheckCirclePurpleIcon,
  chevronDown: ChevronDownIcon,
  chevronRight: ChevronRightIcon,
  chevronUp: ChevronUpIcon,
  circleArrowLeftGrey: CircleArrowLeftGreyIcon,
  circleCheck: CircleCheckIcon,
  cirlePlus: CirlePlusIcon,
  circleInfo: CircleInfoIcon,
  circleInfoBlack: CircleInfoBlackIcon,
  circleSelected: CircleSelectedIcon,
  circleDot: CircleDotIcon,
  circleX: CircleXIcon,
  circleXRed: CirleXRedIcon,
  circleXWarning: CircleXWarningIcon,
  circleUser: CircleUserIcon,
  clock: ClockIcon,
  coinGradient: CoinGradientIcon,
  coin: CoinIcon,
  coinDocument: CoinDocument,
  creditCard: CreditCardIcon,
  currencyDollarExchange: CurrencyDollarExchangeIcon,
  document: DocumentIcon,
  documents: DocumentsIcon,
  deviceMobile: DeviceMobileIcon,
  copy: CopyIcon,
  download: DownloadIcon,
  door: DoorIcon,
  edit: EditIcon,
  eyeOff: EyeOffIcon,
  eye: EyeIcon,
  errorCircle: ErrorCircleIcon,
  errorWarningCircle: ErrorWarningCircleIcon,
  group: GroupIcon,
  faqs: FaqsIcon,
  faceId: FaceIdIcon,
  headset: HeadsetIcon,
  heart: HeartIcon,
  idCard: IdCardIcon,
  iDCardAmerican: IDCardAmericanIcon,
  licenseCardAmerican: LicenseCardAmericanIcon,
  passport: PassportIcon,
  link: LinkIcon,
  linkedAccount: LinkedAccountIcon,
  unLinkedAccount: UnLinkedAccountIcon,
  lock: LockIcon,
  logout: LogoutIcon,
  location: LocationIcon,
  mail: MailIcon,
  menu: MenuIcon,
  message: MessageIcon,
  more: MoreIcon,
  mxFlag: MXIcon,
  newArrowRight: NewArrowRightIcon,
  newCopy: NewCopyIcon,
  users: UsersIcon,
  piggyBank: PiggyBankIcon,
  phone: PhoneIcon,
  priceCoin: PriceCoinIcon,
  plus: PlusIcon,
  refresh: RefreshIcon,
  rightLeft: RightLeftIcon,
  search: SearchIcon,
  share: ShareIcon,
  settings: SettingsIcon,
  send: SendIcon,
  shieldCheck: ShieldCheckIcon,
  speakerPhone: SpeakerPhoneIcon,
  switch: SwitchIcon,
  trash: TrashIcon,
  trashGray: TrashGrayIcon,
  timeClockLoading: TimeClockLoadingIcon,
  timeClockStop: TimeClockStopIcon,
  underReview: UnderReviewIcon,
  upload: UploadIcon,
  uploadLine: UploadLineIcon,
  usaFlagCircle: USIcon,
  usaFlag: USAFlagIcon,
  user: UserIcon,
  visaPassport: VisaPassportIcon,
  wallet: WalletIcon,
  whatsApp: WhatsAppIcon,
  warningCircle: WarningCircleIcon,
  squareMail: SquareMailIcon,
  star: StarIcon,
  world: WorldIcon,
  options: OptionsIcon,
  payroll: PayrollIcon,
  flameDiscount: FlameDiscountIcon,
  flameDiscountGradient: FlameDiscountGradientIcon,
  discountPercentBadge: DiscountPercentBadge,
  discountPercentFire: DiscountPercentFire,
};

export const imgTypes: ImgTypes = {
  cardSendola: CardSendola,
  cardDisableSendola: CardDisableSendola,
  loaderImage: LoaderImage,
};

export const handleSize = (size: string) => {
  switch (size) {
    case "xsmall":
      return "14px";
    case "small":
      return "16px";
    case "small-2":
      return "24px";
    case "medium":
      return "24px";
    case "large":
      return "32px";
    case "large2":
      return "40px";
    case "xlarge":
      return "60px";
    case "xxlarge":
      return "100px";
    default:
      return "18px";
  }
};

export const handleColor = (color: string) => {
  switch (color) {
    case "Primary01":
      return (props: { theme: { Primary_01: string } }) =>
        props.theme.Primary_01;
    case "white":
      return (props: { theme: { Background_01: string } }) =>
        props.theme.Background_01;
    case "black":
      return (props: { theme: { Background_02: string } }) =>
        props.theme.Background_02;
    case "dark":
      return (props: { theme: { Grey_01: string } }) => props.theme.Grey_01;
    case "grey":
      return (props: { theme: { Content_UX_02: string } }) =>
        props.theme.Content_UX_02;
    case "grey02":
      return (props: { theme: { Grey_02: string } }) => props.theme.Grey_02;
    case "grey-blue":
      return (props: { theme: { Primary_09: string } }) =>
        props.theme.Primary_09;
    case "black_highlight":
      return (props: { theme: { Content_UX_01: string } }) =>
        props.theme.Content_UX_01;
    case "solid_light":
      return (props: { theme: { Primary_01: string } }) =>
        props.theme.Primary_01;
    case "solid_2":
      return (props: { theme: { Primary_01: string } }) =>
        props.theme.Primary_01;
    case "success":
      return (props: { theme: { Primary_06: string } }) =>
        props.theme.Primary_06;
    case "warning":
      return (props: { theme: { Primary_08: string } }) =>
        props.theme.Primary_08;
    case "warningStrong":
      return (props: { theme: { Background_17: string } }) =>
        props.theme.Background_17;
    case "Tanhide_Color":
      return (props: { theme: { Tanhide_Color: string } }) =>
        props.theme.Tanhide_Color;
    case "error":
      return (props: { theme: { Red_08: string } }) => props.theme.Red_08;
    case "transparent":
      return (props: { theme: { Transparent: string } }) =>
        props.theme.Transparent;
    case "neutral":
      return (props: { theme: { Content_State_UX_Disabled: string } }) =>
        props.theme.Content_State_UX_Disabled;
    case "neutral_2":
      return (props: { theme: { Neutral_600: string } }) =>
        props.theme.Neutral_600;
    case "neutral_700":
      return (props: { theme: { Neutral_700: string } }) =>
        props.theme.Neutral_700;
    case "Red10":
      return (props: { theme: { Red_10: string } }) => props.theme.Red_10;
    case "Red11":
      return (props: { theme: { Red_11: string } }) => props.theme.Red_11;
    case "primary_500":
      return (props: { theme: { Primary_500: string } }) =>
        props.theme.Primary_500;
    case "Primary_16":
      return (props: { theme: { Primary_16: string } }) =>
        props.theme.Primary_16;
    case "grey_05":
      return (props: { theme: { Grey_05: string } }) => props.theme.Grey_05;
    case "linear":
      return (props: { theme: { Gradient_04: string } }) =>
        props.theme.Gradient_04;
    case "dark_01":
      return (props: { theme: { Dark_01: string } }) => props.theme.Dark_01;
    case "primary_17":
      return (props: { theme: { Primary_17: string } }) =>
        props.theme.Primary_17;
    case "yellow":
      return (props: { theme: { Secondary_Coppel: string } }) =>
        props.theme.Secondary_Coppel;
    case "bannerBank":
      return (props: { theme: { Background_Banner_Bank: string } }) =>
        props.theme.Background_Banner_Bank;
    case "Dark_02":
      return (props: { theme: { Dark_02: string } }) => props.theme.Dark_02;
    case "Dark_03":
      return (props: { theme: { Dark_03: string } }) => props.theme.Dark_03;
    case "Green_06":
      return (props: { theme: { Green_06: string } }) => props.theme.Green_06;
    case "grey_08":
      return (props: { theme: { Grey_08: string } }) => props.theme.Grey_08;
    case "Foreground_Purple_01":
      return (props: { theme: { Foreground_Purple_01: string } }) =>
        props.theme.Foreground_Purple_01;
    case "pink_100":
      return (props: { theme: typeof theme }) => props.theme.Primary_03_100;
    case "primary_01_700":
      return (props: { theme: typeof theme }) => props.theme.Primary_01_700;
    case "Primary500":
      return (props: { theme: typeof theme }) => props.theme.Primary_500;
    case "ZelleColor":
      return (props: { theme: typeof theme }) => props.theme.ZelleColor;
    case "VenmoColor":
      return (props: { theme: typeof theme }) => props.theme.VenmoColor;
    case "AlertBoxColor":
      return (props: { theme: typeof theme }) => props.theme.Background_18;
  }
};

export const iconFontTypes = [
  "alert",
  "arrow-left",
  "arrow-right",
  "arrows",
  "average-smile",
  "bell",
  "building-bank",
  "calendar-event",
  "camera",
  "cash",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "circle-check",
  "circle-exclamation",
  "circle-minus",
  "circle-plus",
  "circle-x",
  "coin",
  "copy",
  "clock",
  "credit-card",
  "diagonal-arrow",
  "download",
  "edit",
  "email",
  "equal",
  "eye-off",
  "eye",
  "external-link",
  "faqs",
  "fulled-star",
  "home",
  "iconsax-linear-bill",
  "iconsax-linear",
  "info",
  "link",
  "location",
  "lock",
  "logout",
  "menu",
  "message",
  "phone-assistance",
  "phone",
  "picture",
  "pin",
  "plus",
  "resize",
  "sad-smile",
  "search",
  "send",
  "sendola-pay",
  "settings",
  "share",
  "shield-check",
  "star",
  "step-one",
  "step-three",
  "step-two",
  "transaction",
  "trash",
  "user-card-id",
  "user-check",
  "user-exclamation",
  "user-id",
  "user-plus",
  "user-x",
  "user",
  "wallet",
  "arrows-flat",
  "user-rounded",
  "user-circle",
  "settings-bars",
  "cancel",
  "hand-coin",
  "safe",
  "pencil",
  "flameDiscount",
] as const;
