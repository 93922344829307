import { useMemo } from "react";

import Icon from "../Icon";

import { FloatingButton } from "./FloatingZelleButton.styled";
import { updateZelleModal, ZelleModalSelector } from "../../Redux/ZelleModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Redux/Store";

const FloatingZelleButton = () => {
  const dispatch = useDispatch<AppDispatch>();
  const showZelleModalSelector = useSelector(ZelleModalSelector);

  const showZelleModal = useMemo(
    () => showZelleModalSelector?.show,
    [showZelleModalSelector]
  );

  const openModal = async () => {
    await dispatch(updateZelleModal(!showZelleModal));
  }

  return (
    <div>
      <FloatingButton onClick={openModal} id="floatingButton">
        <Icon
          icon="currencyDollarExchange"
          color="white"
          fillIcon={false}
          size="medium"
        />
      </FloatingButton>
    </div>
  )
};

export default FloatingZelleButton;