import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { JustifyContent } from "../../Models/Column";

import { Column, Container, Row } from "../Grid"
import { ContainerCard, TitleCard } from "./LinkingCard.styled"
import Text from "../Text"
import Button from "../Button";

import Linked from "../../Assets/Icons/linked-account.svg"

const LinkingCard = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  return (
    <ContainerCard>
      <Container>
        <Row>
          <Column span={12} as={TitleCard} justifyContent={JustifyContent.center}>
            <Text size={0} weight={700}>
              {t("LinkedAccounts.textCard")}
            </Text>
          </Column>
          <Column span={12} justifyContent={JustifyContent.center} my={2}>
            <img alt="Link" src={Linked} width={58} height={57} />
          </Column>
          <Column span={12} justifyContent={JustifyContent.center}>
            <Button
              text={t("LinkedAccounts.emptyLinkAccountsButton")}
              variant="primary"
              sizeText={"small"}
              iconButtonLeft="plus"
              sizeButton="large"
              colorIcon="white"
              onClick={() => 
                navigate(
                  "/funds-capture-check-instructions", {
                    state: { firstAccount: true, homePage: true }
                  }
                )
              }
            />
          </Column>
        </Row>
      </Container>
    </ContainerCard>
  )
}

export default LinkingCard
