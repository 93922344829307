import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Select, { PropsValue } from "react-select";

// Own redux
import { AppDispatch } from "../../Redux/Store";
import { selectorLanguage } from "../../Redux/Translate";
import { getQuoteAction } from "../../Redux/Quotation/Quotation.actions";
import { getLocationsGPAction } from "../../Redux/LocationsGP/LocationsGP.actions";
import { getCatalogsAllCountryAction } from "../../Redux/CatalogsCountry/CatalogsCountry.actions";

// Own constants
import { FIRST_ELEMENT, SECOND_ELEMENT } from "../../Constants/SwitchButton";
import { PaymentsExternalServices } from "../../Constants/LinksExternals";
import {
  CATALOGS_ALL_COUNTRY_GP_FULFILLED,
  CATALOGS_ALL_COUNTRY_GP_REJECTED,
} from "../../Constants/Countries";
import { QUOTATION_FULFILLED, QUOTATION_REJECTED } from "../../Constants/Quote";
import {
  LOCATIONS_GP_FULFILLED,
  LOCATIONS_GP_REJECTED,
} from "../../Constants/Locations";
import {
  KYC_APPROVED,
  KYC_IN_REVIEW,
  KYC_STARTED,
  KYC_SUBMITTED,
} from "../../Constants/KYCStatus";

// Own models
import { AlignItems, JustifyContent } from "../../Models/Column";
import {
  locationsCPUFormValues,
  locationsD2BFormValues,
} from "../../Models/Recipients";
import { OptionCPUCountry, OptionD2BCountry } from "../../Models/LocationsGP";
import { AllCountriesData, OptionSelectCountry } from "../../Models/Countries";
import { DestinationOption, QuoterProps } from "../../Models/Quote";
import { SelectOption } from "../../Models/Select";
// Own utils
import { FormatNumberToThousands } from "../../Utilities/FormatNumberToThousands";
import { FlagIconPath } from "../../Utilities/FlagIcon";
// Own components
import { Column, Row } from "../Grid";
import Icon from "../Icon";
import Text from "../Text";
import SwitchButton from "../SwithButton";
import Button from "../Button";
import Skeleton from "../Skeleton";
import CurrencyInput from "../CurrencyInput";
import { SearchSelect } from "../SearchSelect/SearchSelect.component";
// Own styles
import {
  DivisorLine,
  exchangeRateContainer,
  GlobalPaymentIconRounded,
  NotEmptyOptions,
  QuoterCard,
  SectionFlag,
  SectionFlagWithSelect,
  SectionRecipientGets,
  marginBottomContainer,
  TitleCard,
  CustomText,
  InfoMessage,
  marginVerticalContainer,
} from "./GlobalPaymentQuoter.styled";
import {
  currencySelectRTLStyles,
  selectStylesGlobalPayments,
} from "../ReactSelect/ReactSelect.styled";
import DarkTooltip from "../DarkTooltip";
import { CPU, D2B } from "../../Constants/DeliveryMethod";
import { QuoteDeliveryMethodCode } from "../../Models/QuoteDeliveryMethod";
import { FlexContainer } from "../Flex/Flex.styled";
import { updatePathPayments } from "../../Redux/PathPayments";
import { GetAmountTransactionAction } from "../../Redux/GetAmountTransaction/GetAmountTransaction.actions";
import { GET_AMOUNT_TRANSACTION_FULFILLED } from "../../Constants/Transaction";

const GlobalPaymentsQuoter = (props: QuoterProps) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const lang = useSelector(selectorLanguage);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);
  const [activeSwitch, setActiveSwitch] = useState<string>("");
  const {
    title,
    countryDestination,
    amount,
    kycStatus,
    kycLevel,
    sponsorBank,
    countrySubdivision,
    handleShowNotification,
    setVerifyIdentity,
  } = props;
  const currencyDefault = useRef("MXN");
  // eslint-disable-next-line
  const [D2BOptions, setD2BOptions] = useState<DestinationOption[]>([]);
  // eslint-disable-next-line
  const [CPUOptions, setCPUOptions] = useState<DestinationOption[]>([]);
  const [countries, setCountries] = useState<AllCountriesData[]>([]);
  const [valueD2B, setValueD2B] = useState<OptionD2BCountry>();
  const [valueCPU, setValueCPU] = useState<OptionCPUCountry>();
  const [valueCurrency, setValueCurrency] = useState(currencyDefault.current);
  const [payerQuoteCode, setPayerQuoteCode] = useState<string>();
  const [isLoadingQuote, setisLoadingQuote] = useState<boolean>(false);
  const [countryDestinationValue, setCountryDestinationValue] =
    useState(countryDestination);
  const [currencySend, setCurrencySend] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [amountTyped, setAmountTyped] = useState(amount);
  // eslint-disable-next-line
  const [selectedItem, setSelectedItem] = useState<PropsValue<any>>();
  const [hasCashPickup, setHasCashPickup] = useState<boolean | undefined>();
  const [hasDirectToBank, setHasDirectToBank] = useState<boolean | undefined>();
  const [canShowTooltip, setCanShowTooltip] = useState(false);
  const [amountAvailable, setAmountAvailable] = useState(true);

  const minAmount = useRef<number>(5);
  const maxAmount = useRef<number>();
  const tooltipRef = useRef<HTMLDivElement>(null);
  let timer: ReturnType<typeof setTimeout>;
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    trigger,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const { handleSubmit: handleSubmit2, control: control2 } = useForm({
    mode: "onChange",
  });

  const GetAllCountries = async () => {
    setIsLoading(true);
    const response = await dispatch(
      getCatalogsAllCountryAction({ languageCode: lang.language, sponsorBank })
    );

    if (response?.type === CATALOGS_ALL_COUNTRY_GP_FULFILLED) {
      const listCountry = unwrapResult(response)?.map((country) => ({
        ...country,
        icon: FlagIconPath(country.countryCode),
        image: FlagIconPath(country.countryCode),
        value: country.countryCode,
        label: country.defaultCurrency,
        country: country.countryCode,
      }));

      const defaultCountry =
        listCountry.find((c) => {
          if (countryDestination === "USA") {
            return c.countryCode === "MEX";
          } else {
            return c.countryCode === countryDestination;
          }
        }) ||
        listCountry.find((c) => c.countryCode === "MEX") ||
        listCountry[0];
      currencyDefault.current = defaultCountry?.defaultCurrency;

      setCountries(listCountry);
      setSelectedItem(defaultCountry);
      setCountryDestinationValue(defaultCountry.country);
      setValueCurrency(defaultCountry.defaultCurrency);
      setIsLoading(false);
      setIsError(false);

      return { countries: listCountry, defaultCountry };
    }

    if (response?.type === CATALOGS_ALL_COUNTRY_GP_REJECTED) {
      setIsLoading(false);
      setIsError(true);
    }

    return { countries: [], defaultCountry: null };
  };

  const GetBanksAndLocationsGPByUser = async (
    type: QuoteDeliveryMethodCode,
    countryCode: string,
    currency: string,
    setListOptions: (list: DestinationOption[]) => void,
    setValueSelect?: (value: OptionD2BCountry) => void
  ) => {
    setIsLoading(true);
    if (setValueSelect) {
      setValueSelect({
        value: "",
        label: "",
      });
    }

    const payload = {
      type: type,
      countryCode,
      sponsorBank,
    };

    const response = await dispatch(getLocationsGPAction(payload));

    if (response?.type === LOCATIONS_GP_FULFILLED) {
      const list = unwrapResult(response)?.map((location) => ({
        value: location.id,
        label: location.destination,
        ...location,
      }));
      setListOptions(list);
      setIsLoading(false);
      setIsError(false);
      setPayerQuoteCode(list[0].payerQuote);
      if (type === D2B && list && list.length > 0) {
        setHasDirectToBank(list && list.length > 0);
        setValueD2B(list[0]);
        setValue("DirectToBankSelected", list[0]);
        trigger("DirectToBankSelected");

        minAmount.current = list[0].minimumSendAmount || 5;
        maxAmount.current = list[0].maximumSendAmount;

        await getQuote(amountTyped, countryCode, currency, list[0].payerQuote);
      } else if (type === CPU) {
        const deliveryHashCashPickup = list && list.length > 0;
        setHasCashPickup(deliveryHashCashPickup);
        setCanShowTooltip(!deliveryHashCashPickup);
      }
    }

    if (response?.type === LOCATIONS_GP_REJECTED) {
      if (type === CPU) {
        return setHasCashPickup(false), setCanShowTooltip(true);
      } else if (type === D2B) {
        return setHasDirectToBank(false), setCanShowTooltip(true);
      }

      setListOptions([]);
      setIsLoading(false);
    }
  };

  const onSubmitD2B: SubmitHandler<locationsD2BFormValues> = async (data) => {
    if (!amountTyped || Number(amountTyped) < minAmount.current) {
      setError("amount", {
        type: "custom",
        message: t("HomePage.GlobalPaymentsQuoter.minimumAmountError", {
          amount: minAmount.current,
        }),
      });
      return;
    }

    setIsLoading(true);
    setIsError(false);

    const baseUrl = PaymentsExternalServices;
    const path = `/dashboard?deliveryMethod=D2B&amount=${amountTyped}&currency=${valueCurrency}&payerQuote=${data.DirectToBankSelected?.payerQuote}&paymentDestination=${data.DirectToBankSelected?.value}&country=${countryDestinationValue}`;
    const url = new URL(path, baseUrl);

    await dispatch(updatePathPayments(path));

    if (kycStatus === KYC_STARTED && setVerifyIdentity) {
      setVerifyIdentity(true);
      setIsLoading(false);
    }

    if (kycStatus === KYC_APPROVED || kycStatus === KYC_IN_REVIEW) {
      if (countrySubdivision === "PR") {
        return handleShowNotification && handleShowNotification();
      }
      window.location.href = url.href;
    }
  };

  const onSubmitCPU: SubmitHandler<locationsCPUFormValues> = async (data) => {
    if (!amountTyped || Number(amountTyped) < minAmount.current) {
      setError("amount", {
        type: "custom",
        message: t("HomePage.GlobalPaymentsQuoter.minimumAmountError", {
          amount: minAmount.current,
        }),
      });
      return;
    }

    setIsLoading(true);
    setIsError(false);

    const baseUrl = PaymentsExternalServices;
    const path = `/dashboard?deliveryMethod=CPU&amount=${amountTyped}&currency=${valueCurrency}&payerQuote=${data.CashPickUpSelected?.payerQuote}&paymentDestination=${data.CashPickUpSelected?.value}&country=${countryDestinationValue}`;
    const url = new URL(path, baseUrl);

    await dispatch(updatePathPayments(path));

    if (kycStatus === KYC_STARTED && setVerifyIdentity) {
      setVerifyIdentity(true);
      setIsLoading(false);
    }

    if (kycStatus === KYC_APPROVED || kycStatus === KYC_IN_REVIEW) {
      if (countrySubdivision === "PR") {
        return handleShowNotification && handleShowNotification();
      }
      window.location.href = url.href;
    }
  };

  const getQuote = async (
    amount: number,
    countryDestination: string,
    currencyDestination: string,
    payerQuoteCode: string
  ) => {
    setisLoadingQuote(true);

    const payload = {
      amount,
      countryDestination,
      currencyDestination,
      payerQuoteCode,
    };

    const response = await dispatch(getQuoteAction(payload));
    if (response.type === QUOTATION_FULFILLED) {
      if (response?.payload && response?.payload.length > 0) {
        setCurrencySend(response?.payload[0].amountToReceive);
        setExchangeRate(response?.payload[0].fx);
        setisLoadingQuote(false);
      }
    }

    if (response.type === QUOTATION_REJECTED) {
      setCurrencySend(0);
      setisLoadingQuote(false);
    }
  };

  const HandleChangeCountry = (option: OptionSelectCountry) => {
    setisLoadingQuote(true);
    GetBanksAndLocationsGPByUser(
      D2B,
      option.country!,
      option.shortLabel!,
      setD2BOptions,
      setValueD2B
    );
    GetBanksAndLocationsGPByUser(
      CPU,
      option.country!,
      option.shortLabel!,
      setCPUOptions,
      setValueCPU
    );
  };

  const UpdateQuote = (payerQuoteCode: string) => {
    const country = selectedItem.country;
    getQuote(amountTyped, country, valueCurrency, payerQuoteCode);
  };

  const initializeData = async () => {
    const { defaultCountry } = await GetAllCountries();
    if (defaultCountry) {
      GetBanksAndLocationsGPByUser(
        D2B,
        defaultCountry.countryCode,
        currencyDefault.current,
        setD2BOptions
      );
      GetBanksAndLocationsGPByUser(
        CPU,
        defaultCountry.countryCode,
        currencyDefault.current,
        setCPUOptions
      );
    }
    setValue("amount", 500);
  };

  const GetAmount = async () => {
    const response = await dispatch(GetAmountTransactionAction("0"));

    if (response.type === GET_AMOUNT_TRANSACTION_FULFILLED) {
      if (kycLevel === 1 && response?.payload.data.totalAmount === 999) {
        setAmountAvailable(false);
      }
    }
  };

  useEffect(() => {
    initializeData();
    GetAmount();
  }, []);

  const handleChangeAmount = (value: number) => {
    clearTimeout(timer);

    clearErrors("amount");
    setAmountTyped(value);
    setValue("amount", value ? value.toString() : 0);
    if (!value || Number(value) < minAmount.current) {
      setError("amount", {
        type: "custom",
        message: t("HomePage.GlobalPaymentsQuoter.minimumAmountError", {
          amount: minAmount.current,
        }),
      });
      return;
    }
    if (maxAmount.current && value && Number(value) > maxAmount.current) {
      setError("amount", {
        type: "custom",
        message: t("HomePage.GlobalPaymentsQuoter.maximumAmountError", {
          amount: maxAmount.current,
        }),
      });
      return;
    }
    trigger("amount");
    timer = setTimeout(async () => {
      if (payerQuoteCode) {
        await getQuote(
          value,
          countryDestinationValue,
          valueCurrency,
          payerQuoteCode
        );
      }
    }, 1000);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        tooltipRef.current &&
        !tooltipRef.current?.contains(event.target as Node)
      ) {
        setCanShowTooltip(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipRef]);

  const selectOptions = useMemo(() => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    return countries.map((exchangeRate: any) => ({
      label: `${exchangeRate?.defaultCurrency} ${exchangeRate?.countryName}`,
      value: `${exchangeRate?.defaultCurrency} - ${exchangeRate?.countryCode}`,
      country: exchangeRate?.countryCode,
      image: exchangeRate?.icon,
      shortLabel: exchangeRate?.defaultCurrency,
    }));
  }, [countries]);

  return (
    <>
      <Row>
        <Column span={12} mt={2} justifyContent={JustifyContent.flexStart}>
          <TitleCard>
            <Icon
              icon="send"
              color="Foreground_Purple_01"
              size="medium"
              fillIcon={false}
            />
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </TitleCard>
        </Column>
      </Row>
      <Row as={QuoterCard}>
        <Column span={12}>
          <Row>
            <Column span={12}>
              <Text
                size={0}
                color="Foreground_Purple_01"
                align="left"
                weight="500"
                margin="0"
              >
                {t("HomePage.GlobalPaymentsQuoter.AmountSendTitle")}
              </Text>
            </Column>
            <Column
              span={6}
              alignItems={AlignItems.baseline}
              justifyContent={JustifyContent.flexStart}
            >
              <CurrencyInput
                id="amount"
                label="amount"
                name="amount"
                defaultValue={amount > 0 ? amount : null}
                placeholder="0.00"
                align="left"
                smallPadding
                noBorder
                allowDecimals
                maxLength={5}
                rules={{
                  required: t("Forms.required"),
                }}
                customError={Boolean(errors["amount"])}
                onValueChange={handleChangeAmount}
              />
            </Column>
            <Column
              span={6}
              alignItems={AlignItems.baseline}
              justifyContent={JustifyContent.flexEnd}
            >
              <SectionFlag>
                <GlobalPaymentIconRounded>
                  <Icon icon="usaFlagCircle" size="small-2" />
                </GlobalPaymentIconRounded>
                <Text
                  size={1}
                  color="black"
                  align="left"
                  weight="500"
                  margin="0"
                >
                  USD
                </Text>
              </SectionFlag>
            </Column>
          </Row>
          {errors && errors["amount"] && (
            <Row>
              <Column span={12}>
                <FlexContainer gap="5px">
                  <Icon
                    icon="alertTriangle"
                    color="error"
                    size="small"
                    fillIcon={false}
                  />
                  <Text color="error" size={0} align="left" margin={0}>
                    {errors["amount"].message?.toString()}
                  </Text>
                </FlexContainer>
              </Column>
            </Row>
          )}
          <DivisorLine />
          <Row>
            <Column span={12}>
              <Text
                size={0}
                color="Foreground_Purple_01"
                align="left"
                weight="500"
                margin="0"
              >
                {t("HomePage.GlobalPaymentsQuoter.AmountRecipientsTitle")}
              </Text>
            </Column>
            <Column
              span={6}
              alignItems={AlignItems.baseline}
              justifyContent={JustifyContent.flexStart}
              as={SectionRecipientGets}
            >
              {isLoading || isLoadingQuote ? (
                <Skeleton width={60} height={25} />
              ) : (
                <Text
                  size={18}
                  color="grey"
                  align="left"
                  weight="600"
                  margin="0"
                >
                  {currencySend && currencySend > 0
                    ? FormatNumberToThousands(currencySend.toString() || "0")
                    : "0.00"}
                </Text>
              )}
            </Column>
            <Column
              span={6}
              alignItems={AlignItems.baseline}
              justifyContent={JustifyContent.flexEnd}
            >
              <SectionFlagWithSelect>
                {isLoading ? (
                  <Skeleton width={60} height={25} />
                ) : (
                  <>
                    <SearchSelect
                      labelProps={{ color: "solid_light", size: 0 }}
                      options={selectOptions}
                      name="currency"
                      value={selectedItem}
                      showAvatar
                      showAvatarOnValue
                      customStyles={currencySelectRTLStyles}
                      m="0"
                      onChange={(option: SelectOption) => {
                        setCountryDestinationValue(option.country!);
                        setValueCurrency(option.shortLabel!);
                        setSelectedItem({
                          ...option,
                          label: option.shortLabel,
                        });
                        return HandleChangeCountry(option);
                      }}
                    />
                  </>
                )}
              </SectionFlagWithSelect>
            </Column>
          </Row>
          {exchangeRate !== 0 && valueCurrency && (
            <Row as={exchangeRateContainer}>
              <Column
                span={5}
                alignItems={AlignItems.baseline}
                justifyContent={JustifyContent.flexStart}
              >
                <Text size={1}>
                  {t("HomePage.GlobalPaymentsQuoter.exchangeRate")}
                </Text>
              </Column>
              <Column
                span={7}
                alignItems={AlignItems.baseline}
                justifyContent={JustifyContent.flexEnd}
              >
                <Text size={1}>
                  {t("HomePage.GlobalPaymentsQuoter.exchangeRateUSD")}
                  {`$${exchangeRate} ${valueCurrency}`}
                </Text>
              </Column>
            </Row>
          )}
          <Row
            as={
              exchangeRate && valueCurrency
                ? marginBottomContainer
                : marginVerticalContainer
            }
          >
            <Column span={12}>
              <SwitchButton
                label1={t("HomePage.GlobalPaymentsQuoter.label1")}
                label2={t("HomePage.GlobalPaymentsQuoter.label2")}
                icon1="bank"
                icon2="cash"
                hasCPU={hasCashPickup}
                hasD2B={hasDirectToBank}
                GetActive={setActiveSwitch}
              />
              <div ref={tooltipRef}>
                <DarkTooltip
                  show={canShowTooltip}
                  hasCPU={hasCashPickup}
                  hasShadow={true}
                  textInfo={t(
                    hasDirectToBank
                      ? "HomePage.GlobalPaymentsQuoter.cpuNotAvailable"
                      : "HomePage.GlobalPaymentsQuoter.d2bNotAvailable"
                  )}
                />
              </div>
            </Column>
          </Row>
          {activeSwitch === FIRST_ELEMENT && (
            <Row>
              <Column span={12}>
                <form onSubmit={handleSubmit(onSubmitD2B)}>
                  <Row>
                    {isLoading ? (
                      <Skeleton width={60} height={30} />
                    ) : (
                      <Column span={12}>
                        <Controller
                          control={control}
                          name="DirectToBankSelected"
                          render={({ field: { onChange, ref } }) => (
                            <Select
                              ref={ref}
                              options={D2BOptions}
                              name="DirectToBankSelected"
                              placeholder={t(
                                "HomePage.GlobalPaymentsQuoter.selectRecipent"
                              )}
                              styles={selectStylesGlobalPayments}
                              components={{
                                IndicatorSeparator: () => null,
                                NoOptionsMessage: () => {
                                  return (
                                    <NotEmptyOptions>
                                      {t(
                                        "HomePage.GlobalPaymentsQuoter.notEmptyBanks"
                                      )}
                                    </NotEmptyOptions>
                                  );
                                },
                              }}
                              // eslint-disable-next-line
                              onChange={(option: any) => {
                                onChange(option);
                                UpdateQuote(option.payerQuote);
                                return setValueD2B(option);
                              }}
                              value={valueD2B}
                            />
                          )}
                        />
                      </Column>
                    )}

                    {isError && (
                      <Column span={12} mt={1}>
                        <Text
                          size={0.5}
                          align="center"
                          weight={600}
                          color="error"
                        >
                          {t("404.subtitle")}
                        </Text>
                      </Column>
                    )}
                    <Column span={12}>
                      <InfoMessage>
                        <Icon
                          icon="circleInfoBlack"
                          size="medium"
                          color="Foreground_Purple_01"
                        />
                        <CustomText
                          tagName="p"
                          color={"Foreground_Purple_01"}
                          align="left"
                          size={0}
                          margin="0 0 5px 10px"
                          weight={600}
                        >
                          {t(
                            "HomePage.GlobalPaymentsQuoter.quoteVariationMessage"
                          )}
                        </CustomText>
                      </InfoMessage>
                    </Column>

                    <Column span={12}>
                      <Button
                        variant={
                          isLoading ||
                          (kycStatus === KYC_SUBMITTED && kycLevel === 1) ||
                          (kycStatus === KYC_SUBMITTED &&
                            kycLevel === 2 &&
                            !amountAvailable) ||
                          (kycStatus === KYC_IN_REVIEW && kycLevel === 1) ||
                          (kycStatus === KYC_IN_REVIEW &&
                            kycLevel === 2 &&
                            !amountAvailable)
                            ? "default"
                            : "secondary"
                        }
                        disabled={
                          isLoading ||
                          (kycStatus === KYC_SUBMITTED && kycLevel === 1) ||
                          (kycStatus === KYC_SUBMITTED &&
                            kycLevel === 2 &&
                            !amountAvailable) ||
                          (kycStatus === KYC_IN_REVIEW && kycLevel === 1) ||
                          (kycStatus === KYC_IN_REVIEW &&
                            kycLevel === 2 &&
                            !amountAvailable)
                            ? true
                            : false
                        }
                        text={t("HomePage.GlobalPaymentsQuoter.btnSend")}
                        sizeText="small"
                        sizeButton="medium"
                      />
                    </Column>
                  </Row>
                </form>
              </Column>
            </Row>
          )}
          {activeSwitch === SECOND_ELEMENT && (
            <Row>
              <Column span={12}>
                <form onSubmit={handleSubmit2(onSubmitCPU)}>
                  <Row>
                    <Column span={12}>
                      <Controller
                        control={control2}
                        name="CashPickUpSelected"
                        rules={{
                          required: t("Forms.required"),
                        }}
                        render={({ field: { onChange, ref } }) => (
                          <Select
                            ref={ref}
                            options={CPUOptions}
                            name="CashPickUpSelected"
                            placeholder={t(
                              "HomePage.GlobalPaymentsQuoter.selectLocation"
                            )}
                            styles={selectStylesGlobalPayments}
                            components={{
                              IndicatorSeparator: () => null,
                              NoOptionsMessage: () => {
                                return (
                                  <NotEmptyOptions>
                                    {t(
                                      "HomePage.GlobalPaymentsQuoter.notEmptyLocations"
                                    )}
                                  </NotEmptyOptions>
                                );
                              },
                            }}
                            // eslint-disable-next-line
                            onChange={(option: any) => {
                              onChange(option);
                              UpdateQuote(option.payerQuote);
                              return setValueCPU(option);
                            }}
                            value={valueCPU}
                          />
                        )}
                      />
                    </Column>

                    {isError && (
                      <Column span={12} mt={1}>
                        <Text
                          size={0.5}
                          align="center"
                          weight={600}
                          color="error"
                        >
                          {t("404.subtitle")}
                        </Text>
                      </Column>
                    )}
                    <Column span={12}>
                      <InfoMessage>
                        <Icon
                          icon="circleInfoBlack"
                          size="medium"
                          color="Primary01"
                        />
                        <CustomText
                          tagName="p"
                          color={"Primary01"}
                          align="left"
                          size={0}
                          margin="0 0 5px 10px"
                          weight={600}
                        >
                          {t(
                            "HomePage.GlobalPaymentsQuoter.quoteVariationMessage"
                          )}
                        </CustomText>
                      </InfoMessage>
                    </Column>

                    <Column span={12}>
                      <Button
                        variant={
                          isLoading ||
                          (kycStatus === KYC_SUBMITTED && kycLevel === 1) ||
                          (kycStatus === KYC_SUBMITTED &&
                            kycLevel === 2 &&
                            !amountAvailable) ||
                          (kycStatus === KYC_IN_REVIEW && kycLevel === 1) ||
                          (kycStatus === KYC_IN_REVIEW &&
                            kycLevel === 2 &&
                            !amountAvailable)
                            ? "default"
                            : "secondary"
                        }
                        disabled={
                          isLoading ||
                          (kycStatus === KYC_SUBMITTED && kycLevel === 1) ||
                          (kycStatus === KYC_SUBMITTED &&
                            kycLevel === 2 &&
                            !amountAvailable) ||
                          (kycStatus === KYC_IN_REVIEW && kycLevel === 1) ||
                          (kycStatus === KYC_IN_REVIEW &&
                            kycLevel === 2 &&
                            !amountAvailable)
                            ? true
                            : false
                        }
                        text={t("HomePage.GlobalPaymentsQuoter.btnSend")}
                        sizeText="small"
                        sizeButton="medium"
                      />
                    </Column>
                  </Row>
                </form>
              </Column>
            </Row>
          )}
        </Column>
      </Row>
    </>
  );
};

export default GlobalPaymentsQuoter;
